import { createGlobalStyle } from 'styled-components'
import {
  LIGHT_THEME_NAME,
} from '@/constants/names'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Istok+Web:400,700&display=swap&subset=cyrillic');
  @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap&subset=cyrillic');
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-size: ${({ theme }) => theme.htmlFontSize}px;
  }
  
  body {
    overflow: hidden;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${({ theme }) => theme.colors.colors.default};
    ${({ theme }) =>`
      background-image: url(${theme.backgroundImage});
      background-size: cover;
      background-repeat: no-repeat;
      `
    }
    }}
    @media (max-width: 910px) {
      background-color: #FAFBFB;
    }
  }

  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.colors.default};
    cursor: pointer;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
  }

  .app-container {
    text-align: center;
  }

  /** important for disable default styles */
  .Toastify__toast-container {
    width: 360px !important;

    &.Toastify__toast-container--top-right {
      top: 4em;
    }
  }
  .Toastify__toast {
    padding: 0 !important;
    color: transparent !important;
    background: transparent !important;
  }
  #portal-tooltip {
    width: 0px;
    height: 0px;
    top: 0px;
    left: 0px;
  }
  #globalPopup {
    width: 0px;
    height: 0px;
    top: 0px;
    left: 0px;
  }

  ${({ theme }) => theme.animations.alarm.define}
  ${({ theme }) => theme.animations.notification.define}
`

export default GlobalStyle
