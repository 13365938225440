import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LogoDarkIcon = ({ color, onClick }) => {
  const {
    theme,
  } = useSvgColors(color)
  const fill = theme.colors.colors.logo

  return (
    <svg onClick={onClick} width="268" height="24" viewBox="0 0 268 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M53.446 6.53541H49.1667L49.3249 5.50828L51.5683 3.38351L51.8746 3.07134C52.3589 2.57791 52.5763 2.10462 52.5368 1.67162C52.5071 1.32924 52.3292 1.15805 52.013 1.14798C51.7857 1.13791 51.5979 1.23861 51.4497 1.41987C51.2915 1.6112 51.2026 1.86295 51.1631 2.16505L49.7399 2.17511C49.7597 1.77232 49.8783 1.39973 50.0957 1.06742C50.3131 0.735108 50.5997 0.473289 50.9654 0.281959C51.3311 0.0906298 51.7165 0 52.1217 0C52.7344 0.01007 53.2187 0.181259 53.5547 0.513569C53.8907 0.845878 54.0389 1.28896 53.9994 1.84281C53.9698 2.17511 53.8709 2.49735 53.6832 2.80952C53.4954 3.12169 53.2285 3.454 52.8728 3.79638L52.2106 4.41065L51.2322 5.37737L53.614 5.38744L53.446 6.53541Z" fill="#F3A53A"/>
      <path d="M58.3281 4.02794H58.9507L58.753 5.17592H58.1304L57.8932 6.54543H56.4701L56.7073 5.17592H54.3453L54.4244 4.19913L57.5276 0.120786V0.0905762L59.0001 0.110716L58.3281 4.02794ZM55.8277 4.01787L56.9049 4.02794L57.2904 2.03408L55.8277 4.01787Z" fill="#F3A53A"/>
      <path d="M22.7354 8.07511V3.6712H18.1237V11.1609C19.7399 10.0574 21.2808 9.03559 22.7354 8.07511Z" fill={fill}/>
      <path d="M5.57072 14.0015L9.48207 14.7985C10.5811 15.0131 11.0337 15.3911 11.1738 15.9531C12.5099 15.0131 13.8029 14.1241 15.0528 13.2556C14.2016 12.0907 12.7254 11.3142 10.7751 10.8952L6.89605 10.0778C5.72157 9.84281 5.38754 9.31148 5.38754 8.59622C5.38754 7.58465 6.36807 6.79788 7.89814 6.79788C9.37432 6.79788 10.829 7.61531 11.163 9.29104L15.6885 8.4123C14.9881 5.18345 12.025 3.27271 7.95201 3.27271C3.56655 3.27271 0.549536 5.41846 0.549536 8.86189C0.538761 12.101 2.88772 13.4191 5.57072 14.0015Z" fill={fill}/>
      <path d="M3.976 21.0723C5.35521 20.0709 6.7021 19.1104 8.01666 18.1704C6.36807 18.058 5.19359 17.2508 4.75181 15.5648L0 16.4436C0.323252 18.6506 1.84254 20.214 3.976 21.0723Z" fill={fill}/>
      <path d="M22.7354 12.2849C21.1084 13.0104 19.5675 13.7563 18.1237 14.5022V21.4299H31.9589V17.6187H22.7354V12.2849Z" fill={fill}/>
      <path d="M48.9404 8.4123L44.4149 9.29104C44.0808 7.62552 42.6262 6.79787 41.15 6.79787C39.6092 6.79787 38.6394 7.59487 38.6394 8.59622C38.6394 9.31147 38.9735 9.8428 40.1479 10.0778L44.027 10.8952C47.2703 11.5798 49.1667 13.2556 49.1667 16.0655C49.1667 19.8563 45.6217 21.8182 41.4517 21.8182C37.2279 21.8182 33.7691 19.9074 33.2627 16.4436L38.0145 15.5648C38.4886 17.3938 39.8355 18.1908 41.6996 18.1908C43.4343 18.1908 44.4687 17.4449 44.4687 16.3618C44.4687 15.5648 44.0808 15.0641 42.734 14.7985L38.8226 14.0015C36.1396 13.4191 33.7907 12.0907 33.7907 8.86189C33.7907 5.41846 36.8077 3.27271 41.1931 3.27271C45.2769 3.27271 48.24 5.18345 48.9404 8.4123Z" fill={fill}/>
      <path d="M6.23877 21.6751C6.86372 21.767 7.521 21.8181 8.18906 21.8181C12.3482 21.8181 15.904 19.8563 15.904 16.0655C15.904 15.9326 15.904 15.7998 15.8932 15.667C11.6048 18.0069 8.29681 20.2037 6.23877 21.6751Z" fill={fill}/>
      <path d="M3.73238 24C5.95211 22.2991 17.4207 13.9189 33.8108 8.89913C34.0686 5.3106 36.807 3.38622 41.307 3.2727C41.71 3.28164 41.922 3.28303 42.8115 3.38C40.0356 1.92694 39.831 1.09979 39.831 1.09979C39.831 1.09979 36.4902 -0.0410767 2.1853 23.5021L3.73238 24Z" fill="url(#paint0_linear_3743_170015)"/>
      <path d="M63 13.5452L65.825 9.65923H68.63L65.8851 13.5452L68.63 17.4114H65.825L63 13.5452ZM67.5281 13.5452L70.3531 9.65923H73.1581L70.4132 13.5452L73.1581 17.4114H70.3531L67.5281 13.5452Z" fill={fill}/>
      <path d="M85.9508 10.7497H80.421V18.8786H77.3154V8.2119H85.9508V10.7497Z" fill={fill}/>
      <path d="M87.6726 19.0372C87.125 19.0372 86.6642 18.8521 86.2902 18.482C85.9162 18.1119 85.7292 17.6493 85.7292 17.0942C85.7292 16.5258 85.9162 16.0698 86.2902 15.7261C86.6642 15.3693 87.125 15.1908 87.6726 15.1908C88.2203 15.1908 88.6811 15.3693 89.0551 15.7261C89.4291 16.0698 89.6161 16.5258 89.6161 17.0942C89.6161 17.6493 89.4291 18.1119 89.0551 18.482C88.6811 18.8521 88.2203 19.0372 87.6726 19.0372Z" fill={fill}/>
      <path d="M97.7089 5H109.029V7.55762H100.935V10.4126H104.822C106.518 10.4126 107.807 10.7629 108.688 11.4634C109.583 12.164 110.031 13.1751 110.031 14.4969C110.031 15.8848 109.537 16.962 108.548 17.7286C107.56 18.4952 106.164 18.8786 104.361 18.8786H97.7089V5ZM104.18 16.4994C105.009 16.4994 105.643 16.3408 106.084 16.0235C106.525 15.6931 106.745 15.2173 106.745 14.596C106.745 13.38 105.89 12.772 104.18 12.772H100.935V16.4994H104.18Z" fill={fill}/>
      <path d="M122.364 8.2119V18.8786H119.238V10.7497H115.832L115.732 12.6332C115.679 14.0343 115.545 15.1974 115.332 16.1227C115.118 17.0479 114.771 17.7749 114.29 18.3036C113.809 18.8323 113.141 19.0967 112.286 19.0967C111.832 19.0967 111.291 19.0173 110.663 18.8587L110.823 16.3209C111.037 16.3474 111.191 16.3606 111.284 16.3606C111.752 16.3606 112.099 16.1954 112.326 15.8649C112.567 15.5213 112.727 15.0917 112.807 14.5762C112.887 14.0475 112.947 13.3536 112.987 12.4944L113.148 8.2119H122.364Z" fill={fill}/>
      <path d="M129.557 8.05328C131.227 8.05328 132.509 8.44981 133.404 9.24288C134.299 10.0227 134.746 11.2057 134.746 12.7918V18.8786H131.821V17.5502C131.233 18.5415 130.138 19.0372 128.535 19.0372C127.707 19.0372 126.986 18.8984 126.371 18.6208C125.77 18.3432 125.31 17.9599 124.989 17.4709C124.668 16.9818 124.508 16.4267 124.508 15.8055C124.508 14.8141 124.882 14.0343 125.63 13.4659C126.392 12.8976 127.56 12.6134 129.136 12.6134H131.621C131.621 11.9393 131.414 11.4238 131 11.0669C130.586 10.6968 129.965 10.5118 129.136 10.5118C128.562 10.5118 127.994 10.6043 127.433 10.7893C126.886 10.9612 126.418 11.1991 126.031 11.5031L124.909 9.34201C125.497 8.93226 126.198 8.61504 127.013 8.39033C127.841 8.16563 128.689 8.05328 129.557 8.05328ZM129.317 16.9554C129.851 16.9554 130.325 16.8364 130.739 16.5985C131.153 16.3474 131.447 15.9839 131.621 15.5081V14.4176H129.477C128.195 14.4176 127.554 14.834 127.554 15.6667C127.554 16.0632 127.707 16.3804 128.014 16.6183C128.335 16.843 128.769 16.9554 129.317 16.9554Z" fill={fill}/>
      <path d="M146.097 10.7497H140.567V18.8786H137.462V8.2119H146.097V10.7497Z" fill={fill}/>
      <path d="M152.352 19.0372C151.217 19.0372 150.195 18.8059 149.287 18.3432C148.392 17.8674 147.691 17.2131 147.183 16.3804C146.675 15.5477 146.422 14.6026 146.422 13.5452C146.422 12.4878 146.675 11.5428 147.183 10.71C147.691 9.87732 148.392 9.22966 149.287 8.76704C150.195 8.2912 151.217 8.05328 152.352 8.05328C153.488 8.05328 154.503 8.2912 155.398 8.76704C156.293 9.22966 156.994 9.87732 157.501 10.71C158.009 11.5428 158.263 12.4878 158.263 13.5452C158.263 14.6026 158.009 15.5477 157.501 16.3804C156.994 17.2131 156.293 17.8674 155.398 18.3432C154.503 18.8059 153.488 19.0372 152.352 19.0372ZM152.352 16.4994C153.154 16.4994 153.808 16.235 154.316 15.7063C154.837 15.1644 155.097 14.444 155.097 13.5452C155.097 12.6464 154.837 11.9327 154.316 11.404C153.808 10.862 153.154 10.5911 152.352 10.5911C151.551 10.5911 150.89 10.862 150.369 11.404C149.848 11.9327 149.587 12.6464 149.587 13.5452C149.587 14.444 149.848 15.1644 150.369 15.7063C150.89 16.235 151.551 16.4994 152.352 16.4994Z" fill={fill}/>
      <path d="M168.647 13.3073C169.916 13.7303 170.551 14.5762 170.551 15.8451C170.551 16.81 170.183 17.5568 169.449 18.0855C168.714 18.6142 167.619 18.8786 166.163 18.8786H160.413V8.2119H165.922C167.271 8.2119 168.327 8.45642 169.088 8.94548C169.849 9.42131 170.23 10.0954 170.23 10.9678C170.23 11.4965 170.09 11.9657 169.809 12.3755C169.542 12.772 169.155 13.0826 168.647 13.3073ZM163.278 12.5936H165.642C166.737 12.5936 167.285 12.2102 167.285 11.4436C167.285 10.6902 166.737 10.3135 165.642 10.3135H163.278V12.5936ZM165.902 16.777C167.051 16.777 167.625 16.387 167.625 15.6072C167.625 15.1974 167.492 14.9 167.225 14.715C166.957 14.5167 166.537 14.4176 165.962 14.4176H163.278V16.777H165.902Z" fill={fill}/>
      <path d="M183.355 13.5849C183.355 13.6245 183.335 13.9021 183.295 14.4176H175.14C175.287 15.0785 175.634 15.6006 176.182 15.9839C176.73 16.3672 177.411 16.5589 178.226 16.5589C178.787 16.5589 179.281 16.4796 179.708 16.3209C180.149 16.1491 180.556 15.8848 180.93 15.5279L182.593 17.3123C181.578 18.4622 180.096 19.0372 178.145 19.0372C176.93 19.0372 175.855 18.8059 174.92 18.3432C173.985 17.8674 173.263 17.2131 172.756 16.3804C172.248 15.5477 171.995 14.6026 171.995 13.5452C171.995 12.501 172.242 11.5626 172.736 10.7299C173.243 9.88393 173.931 9.22966 174.8 8.76704C175.681 8.2912 176.663 8.05328 177.745 8.05328C178.8 8.05328 179.755 8.27798 180.61 8.72739C181.465 9.17679 182.133 9.82445 182.613 10.6704C183.108 11.5031 183.355 12.4746 183.355 13.5849ZM177.765 10.3928C177.057 10.3928 176.462 10.5911 175.982 10.9876C175.501 11.3841 175.207 11.9261 175.1 12.6134H180.409C180.303 11.9393 180.009 11.404 179.528 11.0074C179.047 10.5977 178.459 10.3928 177.765 10.3928Z" fill={fill}/>
      <path d="M202.2 8.2119V18.8786H185.751V8.2119H188.856V16.3408H192.442V8.2119H195.548V16.3408H199.094V8.2119H202.2ZM203.703 16.3408V21H200.797V18.8786H199.014V16.3408H203.703Z" fill={fill}/>
      <path d="M215.678 13.5849C215.678 13.6245 215.658 13.9021 215.618 14.4176H207.463C207.61 15.0785 207.957 15.6006 208.505 15.9839C209.053 16.3672 209.734 16.5589 210.549 16.5589C211.11 16.5589 211.604 16.4796 212.031 16.3209C212.472 16.1491 212.88 15.8848 213.254 15.5279L214.917 17.3123C213.901 18.4622 212.419 19.0372 210.469 19.0372C209.253 19.0372 208.178 18.8059 207.243 18.3432C206.308 17.8674 205.587 17.2131 205.079 16.3804C204.571 15.5477 204.318 14.6026 204.318 13.5452C204.318 12.501 204.565 11.5626 205.059 10.7299C205.567 9.88393 206.254 9.22966 207.123 8.76704C208.004 8.2912 208.986 8.05328 210.068 8.05328C211.123 8.05328 212.078 8.27798 212.933 8.72739C213.788 9.17679 214.456 9.82445 214.937 10.6704C215.431 11.5031 215.678 12.4746 215.678 13.5849ZM210.088 10.3928C209.38 10.3928 208.786 10.5911 208.305 10.9876C207.824 11.3841 207.53 11.9261 207.423 12.6134H212.733C212.626 11.9393 212.332 11.404 211.851 11.0074C211.37 10.5977 210.782 10.3928 210.088 10.3928Z" fill={fill}/>
      <path d="M218.074 8.2119H221.179V12.3755H225.868V8.2119H228.993V18.8786H225.868V14.8934H221.179V18.8786H218.074V8.2119Z" fill={fill}/>
      <path d="M237.114 19.0372C235.966 19.0372 234.93 18.8059 234.009 18.3432C233.1 17.8674 232.386 17.2131 231.865 16.3804C231.357 15.5477 231.104 14.6026 231.104 13.5452C231.104 12.4878 231.357 11.5428 231.865 10.71C232.386 9.87732 233.1 9.22966 234.009 8.76704C234.93 8.2912 235.966 8.05328 237.114 8.05328C238.25 8.05328 239.238 8.2912 240.08 8.76704C240.934 9.22966 241.556 9.89715 241.943 10.7695L239.519 12.0582C238.958 11.0801 238.149 10.5911 237.094 10.5911C236.279 10.5911 235.605 10.8554 235.071 11.3841C234.536 11.9128 234.269 12.6332 234.269 13.5452C234.269 14.4572 234.536 15.1776 235.071 15.7063C235.605 16.235 236.279 16.4994 237.094 16.4994C238.163 16.4994 238.971 16.0103 239.519 15.0322L241.943 16.3408C241.556 17.1867 240.934 17.8476 240.08 18.3234C239.238 18.7993 238.25 19.0372 237.114 19.0372Z" fill={fill}/>
      <path d="M248.591 14.834H246.968V18.8786H243.842V8.2119H246.968V12.3556H248.651L251.576 8.2119H254.902L251.135 13.3271L255.162 18.8786H251.476L248.591 14.834Z" fill={fill}/>
      <path d="M257.842 17.4114L260.587 13.5452L257.842 9.65923H260.647L263.472 13.5452L260.647 17.4114H257.842ZM262.37 17.4114L265.115 13.5452L262.37 9.65923H265.175L268 13.5452L265.175 17.4114H262.37Z" fill={fill}/>
      <defs>
      <linearGradient id="paint0_linear_3743_170015" x1="40.2804" y1="2.05053" x2="5.57121" y2="26.931" gradientUnits="userSpaceOnUse">
      <stop stop-color="#F7D43D"/>
      <stop offset="1" stop-color="#E73930"/>
      </linearGradient>
      </defs>
    </svg>
    // <svg onClick={onClick} fill="none" height="43" viewBox="0 0 104 43" width="104" xmlns="http://www.w3.org/2000/svg">
    //   <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="70.8227" x2="10.126" y1="4.08845" y2="47.4989">
    //     <stop offset="0" stopColor="#f7d43d" />
    //     <stop offset="1" stopColor="#e73930" />
    //   </linearGradient>
    //   <g mask="url(#b)">
    //     <path d="m93.8103 11.937h-7.4717l.2761-1.7975 3.917-3.71835.5349-.5463c.8456-.8635 1.2252-1.69176 1.1562-2.44952-.0518-.59917-.3624-.89875-.9146-.91637-.3969-.01762-.7247.1586-.9835.47581-.2761.33482-.4314.77538-.5005 1.30406l-2.4848.01762c.0346-.7049.2416-1.35693.6212-1.93847.3797-.58154.8801-1.03972 1.5185-1.374551.6385-.334827 1.3115-.493429 2.0189-.493429 1.0699.017622 1.9154.317204 2.5021.89875.5867.58154.8455 1.35692.7765 2.32616-.0518.58154-.2243 1.14546-.5522 1.69176-.3278.54629-.7937 1.12783-1.4149 1.727l-1.1562 1.07497-1.7083 1.69175 4.1586.01763z" fill="#f3a53a" />
    //     <path d="m102.335 7.54893h1.087l-.345 2.00896h-1.088l-.414 2.39661h-2.4845l.4141-2.39661h-4.124l.138-1.70938 5.4184-7.137095v-.052867l2.571.035245zm-4.3661-.01762 1.8808.01762.6733-3.48925z" fill="#f3a53a" />
    //     <g fill={fill} className="svgFill">
    //       <path d="m40.1888 14.6315v-7.70684h-8.0522v13.10694c2.822-1.9311 5.5124-3.7193 8.0522-5.4001z" />
    //       <path d="m10.2187 25.0027 6.8294 1.3947c1.919.3755 2.7091 1.0371 2.9537 2.0206 2.3329-1.6451 4.5905-3.2008 6.7729-4.7207-1.4863-2.0384-4.0637-3.3974-7.469-4.1306l-6.7729-1.4305c-2.0507-.4112-2.63392-1.3411-2.63392-2.5927 0-1.7703 1.71202-3.1472 4.38362-3.1472 2.5774 0 5.1173 1.4305 5.7005 4.3631l7.9017-1.5378c-1.2229-5.6505-6.3966-8.99431-13.5082-8.99431-7.65712 0-12.92493 3.75508-12.92493 9.78111-.01882 5.6683 4.08255 7.975 8.76713 8.9943z" />
    //       <path d="m7.4343 37.3765c2.40814-1.7523 4.7598-3.4332 7.0551-5.0783-2.8785-.1967-4.92916-1.6093-5.70052-4.5597l-8.296815 1.5378c.564405 3.8624 3.217135 6.5982 6.942235 8.1002z" />
    //       <path d="m40.1888 21.9987c-2.8408 1.2695-5.5312 2.5749-8.0522 3.8802v12.1235h24.1567v-6.6697h-16.1045z" />
    //       <path d="m85.9435 15.2216-7.9017 1.5378c-.5832-2.9147-3.123-4.3631-5.7005-4.3631-2.6903 0-4.3836 1.3948-4.3836 3.1471 0 1.2517.5833 2.1816 2.6339 2.5928l6.7729 1.4305c5.6629 1.1981 8.9741 4.1306 8.9741 9.048 0 6.6339-6.1896 10.0671-13.4705 10.0671-7.3749 0-13.4141-3.3438-14.2983-9.4055l8.2968-1.5378c.8278 3.2007 3.1795 4.5955 6.4342 4.5955 3.029 0 4.8351-1.3054 4.8351-3.2008 0-1.3947-.6773-2.2709-3.029-2.7358l-6.8293-1.3947c-4.6846-1.0193-8.786-3.3438-8.786-8.9943 0-6.02603 5.2678-9.78111 12.925-9.78111 7.1303 0 12.3041 3.34381 13.5269 8.99431z" />
    //       <path d="m11.3852 38.4315c1.0911.1609 2.2388.2503 3.4052.2503 7.2621 0 13.4706-3.4332 13.4706-10.0672 0-.2324 0-.4649-.0188-.6973-7.4879 4.0948-13.2636 7.9392-16.857 10.5142z" />
    //     </g>
    //     <path d="m7.00872 42.5c3.87568-2.9766 23.90018-17.6419 52.51788-26.4265.4502-6.27994 5.2314-9.64759 13.0885-9.84626.7037.01565 1.0739.01808 2.627.18778-4.8469-2.54285-5.2041-3.99038-5.2041-3.99038s-5.8332-1.996504-65.73054 39.20416z" fill="url(#a)" />
    //   </g>
    // </svg>
  )
}

LogoDarkIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LogoDarkIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LogoDarkIcon)
